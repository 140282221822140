import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import 'vue-class-component/hooks';

@Component({
  components: {},
  inheritAttrs: false,
})

export default class RadioButton extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Prop({ required: false }) disabled!: boolean;

  internalValue: string | null | undefined = '1';

  mounted(): void {
    if (this.value === undefined) {
      this.internalValue = null;
    } else {
      this.internalValue = this.value ? '1' : '2';
    }
  }

  @Watch('internalValue', { immediate: false }) // downwards
  onChangeToTheInternalValue(newValue): void {
    if (newValue === null) {
      this.$emit('input', undefined);
    } else {
      this.$emit('input', newValue === '1');
    }
  }

  @Watch('value', { immediate: true }) // downwards
  onChangeToTheValue(newValue): void {
    if (newValue === undefined) {
      this.internalValue = undefined;
    } else {
      this.internalValue = newValue ? '1' : '2';
    }
  }
}
