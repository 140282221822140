import { Module } from 'vuex';
import { State } from '@/store/models';
import { Investment } from '@/store/models/investment';
import { Asset } from '@/store/models/asset';
import { formatNumber } from '@/filters/number';

export interface InvestmentsArray<T> extends Array<T> {
  totalLength?: number;
}

/**
 * Function that shows two decimals if there are any
 * @param numb
 */
const showTwoDecimalsOrNone = (numb): number => numb % 1 !== 0 ? Number(formatNumber(numb, 2)) : numb;

export default <Module<Investment[], State>>{
  state: [],
  mutations: {},
  actions: {},
  getters: {
    investmentHasPayments: (state, getters): Function => (id: string): boolean => !!getters.getPaymentsByInvestmentId(id).length,
    investmentHasPaidPayments: (state, getters): Function => (id: string): boolean => !!getters.getPaidPaymentsByInvestmentId(id).length,
    investmentsLoadMore: (state, getters): Function => (position: number): Investment[] => {
      const tempInvestments = getters.getInvestmentsNonDeletedAsset as Investment[];
      const paidInvestments = tempInvestments.filter((investment: Investment): boolean => getters.investmentHasPayments(investment.id));
      const investments: InvestmentsArray<any> = paidInvestments.slice(0, position > tempInvestments.length ? tempInvestments.length : position);

      investments.totalLength = paidInvestments.length;

      return investments;
    },
    // Since we cannot do a proper 'join' to get invesments with conditioned asset, we need to do it client side
    getInvestmentsNonDeletedAsset: (state): Investment[] => state.filter(
      (investment): boolean => !!investment.asset.id && !(investment.asset as Asset).deleted,
    ),
    getInvestmentById: (state): Function =>
      (id: string): Investment | undefined =>
        state.find((investment): boolean => investment.id === id),
    getInvestmentByAsset: (state): Function =>
      (assetId: string): Investment | undefined =>
        state.find((investment): boolean => investment.asset.id === assetId),
    // Get the number of investments that have at least one paid payment
    getLengthPaidInvestments: (state, getters): number => state.filter(
      (investment): boolean => getters.investmentHasPaidPayments(investment.id),
    ).length,
    // Get the total Euro invested from all investments (if status of payment is 'paid')
    getTotalInvested: (state): number =>
      state.reduce((investmentA, investmentB): number =>
        investmentA + (investmentB.paidEuroTotal || 0), 0),
    getSharesTotalInvested: (state): number =>
      state.reduce((investmentA, investmentB): number => investmentA + (!(investmentB.asset as Asset).premium ? (investmentB.boughtSharesTotal || 0) : 0), 0),
  },
};
