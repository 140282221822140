/* eslint-disable max-len */
/* eslint no-console: "off" */
import VueGtm from '@gtm-support/vue2-gtm';
import Vue from 'vue';
import router from '@/router';
import { intercom } from '../whitelabel.config';

const { intercomConfig } = require('../whitelabel.config');

const env = process.env;
const NODE_ENV = env.NODE_ENV;
const isProductionEnvironment = NODE_ENV === 'production';
export const localStorageLocation = 'gdprPermissions';

/*
* In order to have our code align with the google documentation we use a dummy gtag function here
* Also the typings of the GTM Vue plugin seem off, since we can pass on arguments to the dataLayer,
* but this has all to be tested in production
* */
function gtag(...args: any): any // overloading to accept any number of arguments
function gtag(): any {
  try {
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    Vue.gtm.dataLayer(arguments);
  } catch (e) {
    // console.error(e);
  }
}

export interface GdprPermission {
  serviceName: string, // for forced updates of consent the serviceName can be changed
  title: { // the title shown to the user
    nl: string,
    en: string,
  },
  bodyText1: { // the the text always shown to users
    nl: string,
    en: string,
  },
  bodyText2?: { // the text shown to the user after clicking 'more'
    nl: string,
    en: string,
  },
  accepted: boolean | undefined, // whether the user has actively given consent or not, undefined indicates that the user has not done either
  required: boolean // indicating whether this tracker is required
  consentRequired: boolean; // if consent is not required the required can't be true
  enable: (app: Vue) => void, // function which enables the specific tracker
  disable: (app: Vue) => void, // function which disables the specific tracker
}

export type GdprPermissions = GdprPermission[];

/* This type corresponds to the object saved in the local storage */
export type GdprPermissionLocalStorage = Pick<GdprPermission, 'serviceName' | 'accepted'>;

/* array which acts as single source of truth of all trackers, and their categorisation */
export const gdprPermissions: GdprPermission[] = [
// export const gdprPermissions: GdprPermission[] = [
//   {
//     serviceName: 'functional',
//     title: {
//       nl: 'Functionele cookies',
//       en: 'Functional cookies',
//     },
//     bodyText1: {
//       nl: '',
//       en: '',
//     },
//     bodyText2: {
//       nl: 'Deze cookies plaatsen wij altijd. Deze cookies zijn noodzakelijk en u kunt deze cookies niet weigeren. De cookies zorgen er bijvoorbeeld voor dat de website technisch goed werkt en beveiligd is.',
//       en: 'These cookies are always used by us. They are required and you cannot opt-out of them. For example ensure these cookies that this site works technically well and is secure.',
//     },
//     accepted: undefined,
//     required: false,
//     consentRequired: false,
//     enable: (app): void => {
//       if (!isProductionEnvironment) {
//         console.log('enable functional cookies');
//       }
//       if (intercomConfig && intercom) {
//         app.$intercom.boot();
//       }
//     },
//     disable: (app): void => {
//       if (!isProductionEnvironment) {
//         console.log('disable functional cookies');
//       }
//       app.$intercom.shutdown();
//     },
//   },
//   {
//     serviceName: 'analytics',
//     title: {
//       nl: 'Analytische cookies',
//       en: 'Analytics cookies',
//     },
//     bodyText1: {
//       nl: '',
//       en: '',
//     },
//     bodyText2: {
//       nl: `Deze cookies plaatsen wij altijd. We gebruiken ze om statistieken te verzamelen over bijvoorbeeld het gebruik van onze website. En om onze site te verbeteren en meer gebruiksvriendelijk te maken. Ook gebruiken we deze cookies om gegevens op te slaan. Bijvoorbeeld:
// - Het IP adres
// - Technische kenmerken zoals de browser die u gebruikt
// - Welk apparaat u gebruikt
// - Vanaf welke site u op onze site bent gekomen
// - Wanneer en hoe lang u onze site bezoekt of gebruikt
// - Welke pagina's u bezoekt op onze site
// - Welke producten u afsluit.`,
//       en: `These cookies are always used by us. They are used to create statistics over, e.g. the usage of our website. And to improve our website and its usability. So do we also use these cookies to save some of your information. So for example:
// - The IP address
// - Technical information like the browser you are using
// - Which device you are using
// - From which site you came to ours
// - When and how long you visit our site or use it
// - The pages you've visited on our site
// - Which products you select`,
//     },
//     accepted: undefined,
//     required: false,
//     consentRequired: false,
//     enable: (): void => {
//       if (!isProductionEnvironment) {
//         console.log('enable Analytics cookies');
//       }
//       gtag('consent', 'update', {
//         analytics_storage: 'granted',
//         wait_for_update: 500,
//       });
//     },
//     disable: (): void => {
//       if (!isProductionEnvironment) {
//         console.log('disable Analytics cookies');
//       }
//       gtag('consent', 'update', {
//         analytics_storage: 'denied',
//         wait_for_update: 500,
//       });
//     },
//   },
//   {
//     serviceName: 'advertisement',
//     title: {
//       nl: 'Advertentiecookies',
//       en: 'Advertisement cookies',
//     },
//     bodyText1: {
//       nl: '',
//       en: '',
//     },
//     bodyText2: {
//       nl: 'We combineren informatie uit advertentiecookies van onze site en sites van derden. Met het volgen van uw online surfgedrag en zoekgedrag doen we u zo relevant mogelijke aanbiedingen. Deze cookies plaatsen we na uw toestemming. U kunt dit soort cookies weigeren door uw instellingen te wijzigen.',
//       en: 'We combine the information from the advertisement cookies with that from third-party sites. By following your surf and search history we make you the best offers available. We only use this cookies after your consent. You can not-accept these cookies by declining them in the settings.',
//     },
//     accepted: undefined,
//     required: false,
//     consentRequired: true,
//     enable: (): void => {
//       if (!isProductionEnvironment) {
//         console.log('enable advertisement cookies');
//       }
//       gtag('consent', 'update', {
//         ad_storage: 'granted',
//         wait_for_update: 500,
//       });
//     },
//     disable: (): void => {
//       if (!isProductionEnvironment) {
//         console.log('disable advertisement cookies');
//       }

//       gtag('consent', 'update', {
//         ad_storage: 'denied',
//         wait_for_update: 500,
//       });
//       gtag('set', 'ads_data_redaction', true);
//     },
//   },
//   {
//     serviceName: 'personalisation',
//     title: {
//       nl: 'Personalisatiecookies',
//       en: 'Personalisation cookies',
//     },
//     bodyText1: {
//       nl: '',
//       en: '',
//     },
//     bodyText2: {
//       nl: 'We combineren informatie van u die bij ons bekend is om u relevante en informatie op maat te tonen in verschillende communicatiekanalen. U kunt dit soort cookies weigeren door uw instellingen te wijzigen.',
//       en: 'For delivering information tailored towards your needs we combine your information which is known to us. We do so on multiple communication channels. We only use this cookies after your consent. You can not-accept these cookies by declining them in the settings',
//     },
//     accepted: undefined,
//     required: false,
//     consentRequired: true,
//     enable: (): void => {
//       if (!isProductionEnvironment) {
//         console.log('enable Personalisation cookies');
//       }
//     },
//     disable: (): void => {
//       console.log('disable');
//     },
//   },
];

export const executePermissions = (permissions: GdprPermissions, app: Vue): void => {
  /* activate gtm with the default all denied */
  gtag('consent', 'default', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
    wait_for_update: 500,
  });
  permissions.forEach((permission): void => {
    if (permission.accepted || !permission.consentRequired) {
      permission.enable(app);
    } else {
      permission.disable(app);
    }
  });
};

/*
* Executed before the new instance is created,
* thus this is the place where plugins for trackers should be initialised
* */
export const trackerSetupBeforeInit = (): void => {
  /* activate gtm
   *  So far we only have google analytics in the GTM in general
   *  If we use the facebook pixel we need to set a variable in the datalayer indicating whether consent is given or not (this should happen in the correct enable() function.
   *  Then there needs to have the right config in the gtm website to have this setting be respected see: https://www.analyticsmania.com/post/facebook-pixel-with-google-tag-manager/#consent
   * Use the consent mode works with gtm https://www.simoahava.com/analytics/consent-mode-google-tags/
   * */
  const analyticsConfig = {
    id: env.VUE_APP_GOOGLE_GTM,
    enabled: isProductionEnvironment, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: !isProductionEnvironment, // Whether or not display console logs debugs (optional)
    loadScript: true,
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    defer: true,
    // ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
  };

  try {
    Vue.use(VueGtm, analyticsConfig);
  } catch (e) {
    // console.error(e);
  }

  if (intercom) {
    Vue.use(require('vue-intercom'), {
      appId: env.VUE_APP_INTERCOM,
    });
  }
};

// in order to not have to ignore all places where we access $intercom we extend Vue here with it
declare module 'vue/types/vue' {
  interface Vue {
    $intercom: any,
  }
}
