import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import RadioButton from '@/components/common/radioButton/RadioButton.vue';
import { GdprPermission } from '@/gdprTrackers';

@Component({
  components: {
    RadioButton,
  },
  inheritAttrs: false,
})

export default class GdprSelectorBox extends Vue {
  @Prop({ required: true }) value!: GdprPermission;

  seeMore = false;

  @Watch('value', { immediate: true })
  onChangeToTheInternalValue(newValue: GdprPermission): void {
    if (!this.value.consentRequired) {
      this.value.accepted = true;
    }
    this.$emit('input', newValue);
  }

  // Animation logic
  enter(element): void {
    const width = getComputedStyle(element).width;

    element.style.width = width;
    element.style.position = 'absolute';
    element.style.visibility = 'hidden';
    element.style.height = 'auto';

    const height = getComputedStyle(element).height;

    element.style.width = null;
    element.style.position = null;
    element.style.visibility = null;
    element.style.height = 0;

    // Force repaint
    // eslint-disable-next-line no-unused-expressions
    getComputedStyle(element).height;

    // Trigger the animation.
    requestAnimationFrame((): void => {
      element.style.height = height;
    });
  }

  afterEnter(element): void {
    element.style.height = 'auto';
  }

  leave(element): void {
    const height = getComputedStyle(element).height;
    element.style.height = height;
    // Force repaint
    // eslint-disable-next-line no-unused-expressions
    getComputedStyle(element).height;
    requestAnimationFrame((): void => {
      element.style.height = 0;
    });
  }
}
