import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import Logo from '@/components/common/logo/Logo.vue';
import GdprSelectorBox from '@/components/gdpr/gdprSelectorBox/GdprSelectorBox.vue';
import { executePermissions, GdprPermission, GdprPermissionLocalStorage, GdprPermissions, localStorageLocation } from '@/gdprTrackers';
import { name } from '../../../../whitelabel.config';

@Component({
  components: {
    Logo,
    GdprSelectorBox,
  },
})
export default class GdprConsentModal extends Vue {
  @Prop({ required: true }) permissions!: GdprPermissions;
  @Action showGDPR!: Function;

  companyName = name;

  get isConfirmDisabled(): boolean {
    // check if all which are required are accepted
    return !this.permissions?.every((permission: GdprPermission): boolean => {
      if (!permission.consentRequired) {
        return true;
      }
      return permission.accepted === undefined ? false : (permission.accepted || !permission.required);
    }) ?? true;
  }

  get brand(): string {
    return require('../../../../whitelabel.config').name;
  }

  get isConsentRequired(): boolean {
    return this.permissions.some((permission): boolean => permission.consentRequired);
  }

  save(): void {
    // set LocalStorage
    localStorage.setItem(
      localStorageLocation,
      JSON.stringify(
        this.permissions.map(({ accepted, serviceName }): GdprPermissionLocalStorage => ({
          accepted,
          serviceName,
        })),
      ),
    );
    // enable tracking
    this.enableTrackers();

    // close modal
    this.$emit('save', true);
    this.showGDPR(false);
  }

  selectAll(): void {
    this.permissions.forEach((permission, index, thisAr): void => {
      if (permission.consentRequired) {
        thisAr[index].accepted = true;
      }
    });
  }

  deselectAll(): void {
    this.permissions.forEach((permission, index, thisAr): void => {
      if (permission.consentRequired) {
        thisAr[index].accepted = false;
      }
    });
  }

  enableTrackers(): void {
    executePermissions(this.permissions, this);
  }
}
