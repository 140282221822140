import VueI18n from 'vue-i18n';
import to from 'await-to-js';
import Vue from 'vue';

Vue.use(VueI18n);

export type Language = 'nl' | 'en';
export const languages: Language[] = ['nl', 'en'];

export const defaultLanguage: Language = 'en';
export const localStorageKey: string = 'vue-i18n-language';

export const isValidLang = (lang): lang is Language => languages.includes(lang);

export const i18n = new VueI18n({
  locale: defaultLanguage, // set locale
  fallbackLocale: 'en',
  messages: {}, // set locale messages
  dateTimeFormats: {
    en: {
      expected: {
        year: 'numeric',
        month: 'long',
      },
    },
    nl: {
      expected: {
        year: 'numeric',
        month: 'long',
      },
    },
  },
  numberFormats: {
    en: {
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
      },
    },
    nl: {
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
      },
    },
  },
});

const isAppleWebkit = navigator.userAgent.indexOf('AppleWebKit') !== -1;

// Setting default language
import(/* webpackChunkName: "lang-[request]" */ `@/lang/${defaultLanguage}.ts`)
  .then((langFile): void => {
    i18n.setLocaleMessage(defaultLanguage, isAppleWebkit ? Object.freeze(langFile.default[defaultLanguage]) : langFile.default[defaultLanguage]);
  })
  .catch((e): void => {
    throw new Error(e);
  });

// Lazy loading of different language
export async function loadLanguageAsync(lang: Language): Promise<boolean> {
  if (i18n.locale !== lang) {
    if (!i18n.messages[lang]) {
      const [msgsError, msgsSuccess] = await to(import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.ts`));
      if (msgsError) {
        console.log('Language error', msgsError); // eslint-disable-line
        return false;
      }
      i18n.setLocaleMessage(lang, msgsSuccess.default[lang]);
    }
    i18n.locale = lang;
  }
  localStorage.setItem(localStorageKey, lang);
  return true;
}
