/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

module.exports = {
  logo: 'joanna.svg',
  logoalt: 'joanna.svg',
  logoShare: 'bloqhouse.png',
  scssVariables: './src/scss/variables/brands/_joanna.scss',
  name: 'Joanna invests',
  phone: '072 303 4803',
  email: 'info@joannainvests.com',
  website: 'https://www.joannainvests.com/',
  glossary: true,
  langFileName: 'joanna.ts',
  modules: {
    landing: false,
    identification: true, // setting this to false requires manual changes
  },
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: false,
  requireLegalBanner: true,
};
